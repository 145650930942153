import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--add-metric-filter-rule-button"
export default class extends Controller {
  incrementRuleIndex(event) {
    const currentHref = this.element.href
    // Match the last integer in the href using a regular expression and increment by 1
    this.element.href = currentHref.replace(/(\d+)$/, (match) => parseInt(match, 10) + 1);
  }
}
