import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--select-metric-to-filter-dropdown"
export default class extends Controller {

  static values = {
    unsupportedMetrics: Array,
    unsupportedMetricsWithMarketingCosts: Array
  }

  static targets = ['select']

  connect() {
    const selectElement = this.selectTarget
    const placeholderValue = selectElement.getAttribute('placeholder')

    const options = {
      minimumResultsForSearch: 5,
      containerCssClass: 'tailwind-select2-wrapper ',
      dropdownCssClass: 'tailwind-select2-dropdown',
      allowClear: false,
      closeOnSelect: true,
      placeholder: placeholderValue
    }

    this.#formatSelectOptions(options)

    const select2Object = $(selectElement).select2(options);

    this.#setSelectOptions(select2Object)

    select2Object.on('select2:select', (event) => {
      // TODO - find a cleaner way to do this... need a shrug emoji.
      const metricIdentifier = select2Object.val()
      const addMetricFilterBtn = document.querySelector(`select#${metricIdentifier}`).parentElement.querySelector('[data-controller="custom-metrics--add-metric-filter-button"]')
      this.application.getControllerForElementAndIdentifier(addMetricFilterBtn, 'custom-metrics--add-metric-filter-button').addOrOpenMetricFilter();
    })
  }

  #setSelectOptions(select2Object) {
    // Only populate the select with options that on currently in the metric formula builder.
    const optionsData = this.#getOptionsDataFromMetrics()
    const superScriptOptionsData = this.#addSuperScriptToDuplicateMetricOptions(optionsData)

    superScriptOptionsData.forEach((data) => {
      const metricAlreadyHasFilters = document.querySelector(`.custom-metric-filter-container[metric-identifier='${data.id}']`)
      if (!metricAlreadyHasFilters) {
        const newOption = new Option(data.text, data.id, false, false);
        newOption.dataset.metricName = data.metricName
        if (this.unsupportedMetricsValue.concat(this.unsupportedMetricsWithMarketingCostsValue).includes(data.metricName)) {
          newOption.disabled = true
        }
        select2Object.append(newOption)
      }
    })

    select2Object.trigger('change');
  }

  #getOptionsDataFromMetrics() {
    const metricElementsInFormula = document.querySelectorAll('.custom-metric-formula-input-wrapper select')
    return Array.from(metricElementsInFormula).map((metricSelectElement) => {
      return {
        id: metricSelectElement.id,
        metricName: metricSelectElement.value,
        text: metricSelectElement.querySelector(`option[value="${metricSelectElement.value}"]`).innerText
      }
    });
  }

  #addSuperScriptToDuplicateMetricOptions(optionsData) {
    const repeatedMetrics = {};

    return optionsData.map(item => {
      const metricName = item.text;
      if (repeatedMetrics[metricName]) {
        // Increment the count for this metric because its been repeated
        // Then append the number to the name
        repeatedMetrics[metricName]++;
        item.text = `${metricName} ${repeatedMetrics[metricName]}`;
      } else {
        // Set to 1 for first occurrence, do nothing else
        repeatedMetrics[metricName] = 1;
      }
      return item;
    })
  }

  #formatSelectOptions = (options) => {
    const formatDisabledState = (option) => {
      let optionHTML;
      if (option.disabled === true) {
        const tooltipText = this.#getDisabledMetricTooltipText(option?.element?.dataset?.metricName)
        optionHTML = `<div
                        class="cursor-pointer"
                        data-controller='tooltips' 
                        data-tooltips-type-value='html'
                        data-tooltip-max-width="260"
                        data-tooltip-position='right'>
                        <div class='tooltip_templates'>
                          <div class='html_tooltip_content'>
                              <p class="mb-0 text-left text-sm text-normal text-gray-950">
                                ${tooltipText}
                              </p>
                          </div>
                        </div>
                        <span>${option.text}</span>
                      </div>`;
      } else {
        optionHTML = `<span>${option.text}</span>`;
      }

      const formattedOption = document.createElement('div')
      formattedOption.innerHTML = optionHTML

      return formattedOption
    }

    options.templateResult = formatDisabledState
  }

  #getDisabledMetricTooltipText(metricName) {
    let tooltipText;

    if (this.unsupportedMetricsWithMarketingCostsValue.includes(metricName)) {
      tooltipText = 'We don’t currently support filters for this metric yet as we can’t accurately filter marketing spend at the order level'
    } else if (this.unsupportedMetricsValue.includes(metricName)) {
      tooltipText = 'This metric cannot be filtered at the moment, but we\'re actively working on it. Please check back in about a week to see if its filterable.'
    }

    return tooltipText;
  }
}
