import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  trackCrossSellIntent(event) {
    if (event.params?.trigger) {
      Rails.ajax({
        url: `/platform/track_cross_sell_intent?trigger=${event.params.trigger}`,
        type: 'post',
      });
    }
  }
}
