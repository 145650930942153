const init = () => {
    const leftMainMenu = $('nav.navbar-main');
    const dropdownTargets = leftMainMenu.find('.navbar-object-dropdown-target');

    // If a nested dropdown item is active, activate the icon/parent too
    $('.navbar-object-nested.active').closest('.navbar-object-dropdown').addClass('active');

    // Should we do this initially here in JS or in the HTML.erb?
    $('.navbar-object-dropdown').filter('.open').each(function () {
        $(this).find('.caret').addClass('!open-caret')
    });

    dropdownTargets.click(function () {
        const dropdown = $(this).closest('.navbar-object-dropdown');
        if (dropdown.hasClass('open')) {
            flipDropdown(dropdown, 'close');
        } else {
            flipDropdown(dropdown, 'open');
            // Close all other dropdowns if this one is opening
            $('.navbar-object-dropdown').not(dropdown).each(function () {
                flipDropdown($(this), 'close');
            });
        }

        // Update cookie for dropdown states
        // Only allow a single open dropdown
        const openDropdown = dropdown.attr('data-dropdown-name');

        // Comment out this cookie update because, with only one dropdown open at any given time, we don't
        // need to store cookies to remember which one is open - by default, the page will always load
        // with the current page's dropdown open.
        // updateLeftNavOpenDropdownCookie( openDropdown );
    });

    // Toggle - expand/collaspe menu
    const mainMenuToggle = $('#left-main-menu-toggle')
    mainMenuToggle.unbind("click"); // Clear previously bound event, need this when refreshing the Dashboard
    mainMenuToggle.click(function (e) {
        e.preventDefault();
        const htmlTag = $('html');
        if (htmlTag.hasClass('navbar-collapsed')) {
            // Set cookie so when page refreshes we remember the nav's state
            updateLeftNavStateCookie('expanded');
            // Now figure out also which dropdown are expanded :)

            htmlTag.removeClass('navbar-collapsed');
        } else {
            updateLeftNavStateCookie('collapsed');
            htmlTag.addClass('navbar-collapsed');
        }
    });

    // Toggle mobile menu
    const htmlTag = $('html');
    $('#mobile-btn-open-left-main-menu').click(function (e) {
        e.preventDefault();
        htmlTag.addClass('navbar-expanding-mobile')
        setTimeout(function () {
            htmlTag.removeClass('navbar-expanding-mobile');
            htmlTag.addClass('navbar-expanded-mobile');
        }, 100)
    });
    $('#mobile-btn-close-left-main-menu').click(function (e) {
        e.preventDefault();
        htmlTag.removeClass('navbar-expanded-mobile');
    });

    // Update the 'active' class on user's click, even before page refreshes, to give a
    // fancy JS front-end framework effect
    $('.navbar-main a.navbar-object, .navbar-main a.navbar-object-nested').click(function (e) {
        const clickedLink = $(this);

        if (
            (clickedLink.attr('target') === '_blank') ||
            (e.ctrlKey ||
                e.shiftKey ||
                e.metaKey || // apple
                (e.button && e.button == 1)) // middle click, >IE9 + everyone else
        ) {
            return;
        }

        $('a.navbar-object, a.navbar-object-nested').removeClass('active');
        $(this).addClass('active');
    });

    // Open AMP app switcher
    $('[id=navbar-amp-app-switcher]').click(function (e) {
        e.preventDefault();
        $('#amp-app-switcher-overlay').addClass('open');
    });
    // Close AMP app switcher - via click outside
    $('#amp-app-switcher-overlay').click(function (e) {
        if (e.target.id === 'amp-app-switcher-overlay') {
            e.preventDefault();
            $('#amp-app-switcher-overlay').removeClass('open');
        }
    });
    // Close AMP app switcher - via close button
    $('[id=close-amp-app-switcher]').click(function (e) {
      e.preventDefault();
      $('#amp-app-switcher-overlay').removeClass('open');
    });
}

const flipDropdown = (jQueryDropdown, action) => {
    // Need to also flip caret
    const caret = jQueryDropdown.find('.caret');
    if (action === 'open') {
        jQueryDropdown.addClass('open');
        caret.addClass('!open-caret');
    } else {
        jQueryDropdown.removeClass('open');
        caret.removeClass('!open-caret');
    }
}

const updateLeftNavStateCookie = (value) => {
    // Updates or creates
    document.cookie = 'lifetimely_left_nav_state' + "=" + value + "; path=/";
}

const updateLeftNavOpenDropdownCookie = (value) => {
    // Updates or creates
    document.cookie = 'lifetimely_left_nav_open_dropdowns' + "=" + value + "; path=/";
}

module.exports = {
    init: init
};
