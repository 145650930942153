import Base_controller from "./base_controller";

// Connects to data-controller="custom-metrics--metric-formula-input"
export default class extends Base_controller {
  static values = {
    focusOnConnectIfEmpty: Boolean,
    keepClosedOnConnect: Boolean,
    unsupportedMetrics: Array,
    metricIdentifier: String
  }

  static targets = ['select', 'ruleCountContainer']

  connect() {
    const selectElement = this.selectTarget;

    const options = {
      minimumResultsForSearch: 5,
      containerCssClass: ['tailwind-select2-wrapper', 'tailwind-blue-custom-metric-select2-wrapper'],
      dropdownCssClass: ['tailwind-blue-small-dropdown-select2-wrapper'],
      allowClear: false,
      closeOnSelect: true,
      dropdownAutoWidth: true
    }

    const placeholderValue = selectElement.getAttribute('placeholder')
    if (placeholderValue !== undefined && placeholderValue !== '') {
      options.placeholder = placeholderValue
    }

    // Set id and name here so that we can attach the metric's filters to it after
    // form submit.
    selectElement.id = this.metricIdentifierValue
    selectElement.name = `formula[${this.metricIdentifierValue}]`

    const select2Element = $(selectElement).select2(options);

    this.#openSelectOnConnectIfEmptyAndAllowed(select2Element)
    this.#initActionsOnSelect(select2Element)
    this.#hideOrShowFiltersButton(select2Element)
  }

  #focusCursor() {
    document.getElementById('cursor_input').focus()
  }

  #openSelectOnConnectIfEmptyAndAllowed(select2Element) {
    if (this.keepClosedOnConnectValue) {
      return;
    }

    // The placeholder "Select a metric..." prompt is technically a selected value
    // who's id is blank (''), so if that is present, open the select. If not, that
    // means a value is already selected, so don't open it.
    if (select2Element.select2('data')[0].id === '') {
      select2Element.select2('open');
    }
  }

  #initActionsOnSelect(select2Element) {
    const submitter = document.querySelector("[type='submit'][value='Refresh builder']")

    select2Element.on('select2:select', (event) => {
      this.#hideOrShowFiltersButton(select2Element)
      submitter.click()
      this.#focusCursor()
    })
  }

  #hideOrShowFiltersButton(select2Element) {
    if (this.#isUnsupportedMetric(select2Element.val())) {
      this.element.classList.add('custom-metric-unsupported-filters')
    } else {
      this.element.classList.remove('custom-metric-unsupported-filters')
    }
  }

  #isUnsupportedMetric(metric) {
    return this.unsupportedMetricsValue.includes(metric);
  }
}

