import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--independent-add-filter-button"
export default class extends Controller {

  static values = {
    unsupportedMetrics: Array
  }

  static targets = ['link', 'text']

  connect() {
    const filteredMetricsCount = document.getElementById('custom_metric_builder_form').querySelectorAll('.custom-metric-filter-container').length
    const totalFilterableMetricsCount = this.#getTotalFilterableMetricsCount()

    this.#setText(filteredMetricsCount)
    this.#setState(filteredMetricsCount, totalFilterableMetricsCount)
  }

  #setText(filteredMetricsCount) {
    if (filteredMetricsCount === 0) {
      this.textTarget.textContent = 'Add filters'
    } else {
      this.textTarget.textContent = 'Add another filter'
    }
  }

  #setState(filteredMetricsCount, totalFilterableMetricsCount) {
    if (filteredMetricsCount === totalFilterableMetricsCount) {
      this.linkTarget.classList.remove('text-blue-400')
      this.linkTarget.classList.add('text-gray-300', 'pointer-events-none')
      this.linkTarget.setAttribute('disabled', true)
    } else {
      this.linkTarget.classList.add('text-blue-400')
      this.linkTarget.classList.remove('text-gray-300', 'pointer-events-none')
      this.linkTarget.removeAttribute('disabled')
    }
  }

  #getTotalFilterableMetricsCount() {
    const metricInputs = document.getElementById('custom_metric_builder_form').querySelectorAll("[data-custom-metrics--metric-formula-input-target='select']")
    const filterableMetricInputs = Array.from(metricInputs).filter((metricInput) => {
      return !this.unsupportedMetricsValue.includes(metricInput.value);
    })

    return filterableMetricInputs.length
  }
}
