import Base_controller from "./base_controller";

// Connects to data-controller="custom-metrics--formula-buttons"
export default class extends Base_controller {
  addMetric() {
    this._getFormulaBoxController().addMetric()
  }

  addOperator(event) {
    this._getFormulaBoxController().addOperator(event.params.value)
  }
}
