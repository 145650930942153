import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--trend-visualization-select"
export default class extends Controller {
  connect() {
    const selectElement = this.element;

    const containerClasses = ['tailwind-select2-wrapper', 'tailwind-light-select2-wrapper']
    const dropdownClass =  'tailwind-select2-dropdown'

    const options = {
      minimumResultsForSearch: -1,
      containerCssClass: containerClasses.join(' '),
      dropdownCssClass: dropdownClass,
      allowClear: false,
      closeOnSelect: true,
    }

    this.#formatSelectAndOptions(options)

    const placeholderValue = this.element.getAttribute('placeholder')
    if (placeholderValue !== undefined && placeholderValue !== '') {
      options.placeholder = placeholderValue
    }

    $(selectElement).select2(options);
  }

  #formatSelectAndOptions = (options) => {
    function formatOperatorState (state) {
      let svgHTML;
      if (state.id === 'true') {
        svgHTML =  `<svg class="inline" width="40" height="22" viewBox="0 0 40 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10.5" transform="matrix(1 0 0 -1 19 21)" fill="#E5F9F3" stroke="white"/>
                    <path d="M33.1948 10.5282C33.3248 10.3982 33.4955 10.3328 33.6662 10.3328C33.8368 10.3328 34.0075 10.3982 34.1375 10.5282C34.3982 10.7888 34.3982 11.2102 34.1375 11.4708L29.4708 16.1375C29.2131 16.3953 28.7983 16.3981 28.537 16.1462C28.5336 16.1429 28.5303 16.1396 28.527 16.1363L23.8615 11.4708C23.6008 11.2102 23.6008 10.7888 23.8615 10.5282C24.1222 10.2675 24.5435 10.2675 24.8042 10.5282L28.3328 14.0568V6.33284C28.3328 5.96417 28.6315 5.66617 28.9995 5.66617C29.3675 5.66617 29.6662 5.96417 29.6662 6.33284V14.0568L33.1948 10.5282Z" fill="#00BF86"/>
                    <circle cx="11" cy="11" r="10.5" fill="#FEEEEE" stroke="white"/>
                    <path d="M15.1948 11.4718C15.3248 11.6018 15.4955 11.6672 15.6662 11.6672C15.8368 11.6672 16.0075 11.6018 16.1375 11.4718C16.3982 11.2112 16.3982 10.7898 16.1375 10.5292L11.4708 5.86249C11.2131 5.60474 10.7983 5.60186 10.537 5.85385C10.5336 5.85708 10.5303 5.86035 10.527 5.86366L5.86152 10.5292C5.60085 10.7898 5.60085 11.2112 5.86152 11.4718C6.12218 11.7325 6.54352 11.7325 6.80418 11.4718L10.3328 7.94316V15.6672C10.3328 16.0358 10.6315 16.3338 10.9995 16.3338C11.3675 16.3338 11.6662 16.0358 11.6662 15.6672V7.94315L15.1948 11.4718Z" fill="#F95252"/>
                    </svg>`
      } else {
        svgHTML =  `<svg class="inline" width="40" height="22" viewBox="0 0 40 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10.5" transform="matrix(1 0 0 -1 19 21)" fill="#FEEEEE" stroke="white"/>
                    <path d="M33.1948 10.5282C33.3248 10.3982 33.4955 10.3328 33.6662 10.3328C33.8368 10.3328 34.0075 10.3982 34.1375 10.5282C34.3982 10.7888 34.3982 11.2102 34.1375 11.4708L29.4708 16.1375C29.2131 16.3953 28.7983 16.3981 28.537 16.1462C28.5336 16.1429 28.5303 16.1396 28.527 16.1363L23.8615 11.4708C23.6008 11.2102 23.6008 10.7888 23.8615 10.5282C24.1222 10.2675 24.5435 10.2675 24.8042 10.5282L28.3328 14.0568V6.33284C28.3328 5.96417 28.6315 5.66617 28.9995 5.66617C29.3675 5.66617 29.6662 5.96417 29.6662 6.33284V14.0568L33.1948 10.5282Z" fill="#F95252"/>
                    <circle cx="11" cy="11" r="10.5" fill="#E5F9F3" stroke="white"/>
                    <path d="M15.1948 11.4718C15.3248 11.6018 15.4955 11.6672 15.6662 11.6672C15.8368 11.6672 16.0075 11.6018 16.1375 11.4718C16.3982 11.2112 16.3982 10.7898 16.1375 10.5292L11.4708 5.86249C11.2131 5.60474 10.7983 5.60186 10.537 5.85385C10.5336 5.85708 10.5303 5.86035 10.527 5.86366L5.86152 10.5292C5.60085 10.7898 5.60085 11.2112 5.86152 11.4718C6.12218 11.7325 6.54352 11.7325 6.80418 11.4718L10.3328 7.94316V15.6672C10.3328 16.0358 10.6315 16.3338 10.9995 16.3338C11.3675 16.3338 11.6662 16.0358 11.6662 15.6672V7.94315L15.1948 11.4718Z" fill="#00BF86"/>
                    </svg>`
      }

      const formattedOption = document.createElement('span')
      formattedOption.innerHTML =  svgHTML + `<span class="ml-2">${state.text}</span>`

      return formattedOption
    }

    options.templateResult = formatOperatorState
    options.templateSelection = formatOperatorState
  }
}
