export function refreshFormulaBuilder() {
    // Replace current metric value preview with skeleton element
    const previewValueContainer = document.querySelector('[data-custom-metric-preview-value]')
    let previewSkeleton = document.createElement('div');
    previewSkeleton.classList.add('bg-gray-10', 'rounded', 'mb-1.5', 'mx-auto', 'w-max', 'skeleton--animated')
    previewSkeleton.innerHTML = '<span class="block text-xl text-gray-900 font-semibold text-center invisible">Loading...</span>'
    previewValueContainer.replaceWith(previewSkeleton);

    // Refresh the validation indicator and the metric preview
    const submitter = document.querySelector("[type='submit'][value='Refresh builder']")
    // Would love to use submitter.form.requestSubmit(submitter) but it doesnt work for Safari, and no
    // polyfill available for current version of Turbo. Maybe in a future one: https://github.com/hotwired/turbo/issues/1071
    // For now, click the button.
    submitter.click()
}