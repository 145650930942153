import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--add-metric-filter-button"
export default class extends Controller {

  static values = {
    url: String
  }

  addOrOpenMetricFilter() {
    if (this.#metricHasFilters()) {
      this.#openMetricFilter()
    } else {
      this.#addMetricFilter()
    }
  }

  disconnect() {
    if (this.#metricHasFilters()) {
      this.#metricFiltersContainer().remove();
    }
  }

  #selectElement() {
    this.selectElement = this.selectElement || this.element.closest('.custom-metric-formula-input-wrapper').querySelector('select')
    return this.selectElement;
  }

  #metricIdentifier() {
    return this.#selectElement().id;
  }

  #metricFiltersContainer() {
    this.metricFiltersContainer = this.metricFiltersContainer || document.querySelector(`.custom-metric-filter-container[metric-identifier="${this.#metricIdentifier()}"]`);
    return this.metricFiltersContainer;
  }

  #metricHasFilters() {
    return this.#metricFiltersContainer() !== null;
  }

  #openMetricFilter() {
    const metricFiltersContainer = this.#metricFiltersContainer();
    const firstRuleControllerElement = metricFiltersContainer.querySelector('[data-custom-metrics--filter-rule-metric-identifier-value]');
    this.application.getControllerForElementAndIdentifier(firstRuleControllerElement, 'custom-metrics--filter-rule').focusValueSelect();

    Turbo.visit('/custom_metrics/show_independent_add_filters_button', { historyChanged: true })
  }

  #addMetricFilter() {
    // Update url params
    // add metric_identifier and metric
    let url = new URL(this.urlValue);

    const metricIdentifier = this.#selectElement().id
    const metric = this.#selectElement().value
    url.searchParams.append('metric_identifier', metricIdentifier);
    url.searchParams.append('metric', metric);

    Turbo.visit(url, { historyChanged: true })
  }
}
