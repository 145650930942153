import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--base"
// This controller houses methods that are reused by multiple other custom metrics controllers.

export default class extends Controller {
  _getFormulaBoxController() {
    const formulaBoxControllerElement = document.querySelector('[data-controller="custom-metrics--formula-box"]')
    return this.application.getControllerForElementAndIdentifier(formulaBoxControllerElement, 'custom-metrics--formula-box');
  }
}