import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="complex-filters-checkbox-select"
export default class extends Controller {
  static values = {
    filterType: String,
    preselectedValues: Array,
    formRefreshOnChangeTrigger: String
  }

  static targets = [
      'toggleBtn',
      'select',
      'search',
      'selectedNodesTable',
      'allNodesTable',
      'applyBtn',
      'cancelBtn',
      'containsInput'
  ]

  connect() {
    this.allNodesTable = this.allNodesTableTarget
    const checkboxSelectDatatableOptions = this.#buildCheckboxSelectDatatableOptions(this.filterTypeValue)
    const allNodesDatatable = $(this.allNodesTableTarget).DataTable(checkboxSelectDatatableOptions);
    this.allNodesDatatable = allNodesDatatable

    this.#initCheckboxSelect()
    this.#initRuleNameOperatorSelect()
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutsideCheckboxListener)
  }

  reset(newFilterType) {
    this.#clearEverything()
    const toggleBtnPlaceholder = this.#buildToggleBtnPlaceholder(newFilterType);
    this.toggleBtnTarget.textContent = toggleBtnPlaceholder;
    this.toggleBtnTarget.dataset.defaultInnerText = toggleBtnPlaceholder;
    this.allNodesDatatable.clear().destroy()
    const checkboxSelectDatatableOptions = this.#buildCheckboxSelectDatatableOptions(newFilterType)
    const allNodesDatatable = $(this.allNodesTableTarget).DataTable(checkboxSelectDatatableOptions)
    this.allNodesDatatable = allNodesDatatable
  }

  handleClickOutsideCheckbox(checkboxSelectContainer, event) {
    if (checkboxSelectContainer.classList.contains('active') && !checkboxSelectContainer.contains(event.target)) {
      checkboxSelectContainer.classList.remove('active')
    }
  }

  getRuleNameOperatorSelectElement() {
    this.ruleNameOperatorSelectElement = this.ruleNameOperatorSelectElement || this.element.closest(".complex-filter-rule").querySelector("select[id*='rule_name_operator']");
    return this.ruleNameOperatorSelectElement;
  }

  clearBtnAction() {
    this.#clearEverything()
    this.allNodesDatatable.ajax.reload()
  }

  #initCheckboxSelect() {
    const checkboxSelectContainer = this.element
    const toggleBtn = this.toggleBtnTarget
    const selectedNodesTable = this.selectedNodesTableTarget
    const nodesSearchInput = $(this.searchTarget)
    const selectElement = this.selectTarget
    const applyBtn = this.applyBtnTarget
    const cancelBtn = this.cancelBtnTarget
    const allNodesTable = this.allNodesTable
    const containsInput = this.containsInputTarget
    const formRefreshOnChangeTrigger = this.formRefreshOnChangeTriggerValue

    toggleBtn.textContent = this.#buildSelectedValuesInnerText(this.preselectedValuesValue);

    toggleBtn.addEventListener('click', (event) => {
      if (checkboxSelectContainer.classList.contains('active')) {
        // Select is open, so close it
        checkboxSelectContainer.classList.remove('active')
      } else {
        // Select is closed, so open it
        checkboxSelectContainer.classList.add('active')

        nodesSearchInput.focus()
        this.allNodesDatatable.ajax.reload()
      }

      const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
      const nodesContainer = checkboxSelectContainer.querySelector('.checkbox-select-nodes-container')
      const nodesContainerBottom = nodesContainer.getBoundingClientRect().bottom
      const nodesContainerHeight = nodesContainer.getBoundingClientRect().height
      const defaultNodesContainerHeight = 353
      const adjustedNodesContainerBottom = (nodesContainerHeight < defaultNodesContainerHeight) ? (nodesContainerBottom + (defaultNodesContainerHeight - nodesContainerHeight)) : nodesContainerBottom;

      if (adjustedNodesContainerBottom >= windowHeight) {
        // Container is out of view, open it above the button
        nodesContainer.classList.add('bottom-9')
      } else {
        // Container is in view, open below the button (default)
        nodesContainer.classList.remove('bottom-9')
      }
    })

    cancelBtn.addEventListener('click', (event) => {
      checkboxSelectContainer.classList.remove('active')
    })

    selectedNodesTable.querySelectorAll('tbody tr').forEach((row) => {
      this.#createToggleCheckmarkEventListener(row)
    })

    nodesSearchInput.on("keypress keydown", this.#delay((event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        applyBtn.click();
      } else {
        this.#redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)
        this.allNodesDatatable.ajax.reload()
      }
    }, 300));

    applyBtn.addEventListener('click', (event) => {
      checkboxSelectContainer.classList.remove('active')
      const selectedValues = this.#redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)
      toggleBtn.textContent = this.#buildSelectedValuesInnerText(selectedValues);
      this.allNodesDatatable.ajax.reload()

      if (formRefreshOnChangeTrigger) {
        document.querySelector(formRefreshOnChangeTrigger).click()
      }
    })

    if (formRefreshOnChangeTrigger) {
      containsInput.addEventListener('focusout', (event) => {
        if (typeof containsInput.value === 'string' && containsInput.value.length > 0) {
          document.querySelector(formRefreshOnChangeTrigger).click()
        }
      })
    }

    this.handleClickOutsideCheckboxListener = this.handleClickOutsideCheckbox.bind(null, checkboxSelectContainer)
    document.addEventListener("click", this.handleClickOutsideCheckboxListener)
  }

  #redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn) {
    // Get existing selected values from Table
    const existingSelectedValues = Array.from(selectedNodesTable.querySelectorAll('tr.checked')).map((row) => {
      return row.getAttribute('data-node-value')
    })
    // Get selected values from dropdown table
    const recentlySelectedValuesElements = allNodesTable.querySelectorAll('tr.checked')
    const recentlySelectedValues = Array.from(recentlySelectedValuesElements).map((row) => {
      return row.getAttribute('data-node-value')
    })
    // find unique values
    const allSelectedValues = existingSelectedValues.concat(recentlySelectedValues)
    const uniqueSelectedValues = [... new Set(allSelectedValues)]

    // clear table and select
    this.#clearSelectedValues(selectedNodesTable, selectElement)

    // re-create unique values
    if (uniqueSelectedValues.length > 0) {
      const tableHead = selectedNodesTable.createTHead()
      const tableHeadRow = tableHead.insertRow()
      tableHeadRow.insertCell(0).outerHTML = `
        <th colspan="2">
          <div class="flex justify-between items-end">
            <span class="block">Selected</span>
            <button class="text-xs underline hover:no-underline hover:opacity-90" type="button" name="clear_all" data-action="click->complex-filters-checkbox-select#clearBtnAction">Clear all</button>
          </div>
        </th>`

      const tableBodyElement = selectedNodesTable.createTBody()
      uniqueSelectedValues.forEach((selectedValue) => {
        this.#createSelectedValuesTableRow(tableBodyElement, selectedValue)
        this.#createOptionForSelect(selectElement, selectedValue)
      })

      toggleBtn.parentElement.classList.remove('text-gray-40')
    } else {
      // If values are empty
      this.#clearToggleBtnValues()
    }

    return uniqueSelectedValues
  }

  #clearEverything() {
    const selectedNodesTable = this.selectedNodesTableTarget
    const selectElement = this.selectTarget
    const nodesSearchInput = $(this.searchTarget)
    const containsInput = this.containsInputTarget
    const toggleBtn = this.toggleBtnTarget

    toggleBtn.innerText = toggleBtn.dataset.defaultInnerText
    containsInput.value = ''
    this.#clearSelectedValues(selectedNodesTable, selectElement)
    this.#clearToggleBtnValues()
    nodesSearchInput.val('')
  }

  #toggleHideContainsInput() {
    const toggleBtn = this.toggleBtnTarget
    const selectElement = this.selectTarget
    const containsInput = this.containsInputTarget

    containsInput.classList.toggle('hidden')
    containsInput.toggleAttribute("disabled")

    selectElement.toggleAttribute("disabled")
    toggleBtn.classList.toggle('hidden')
    toggleBtn.toggleAttribute("disabled")
  }

  #clearToggleBtnValues() {
    const toggleBtn = this.toggleBtnTarget
    toggleBtn.parentElement.classList.add('text-gray-40')
  }

  #clearSelectedValues(selectedValuesTable, selectElement) {
    // clear table and select
    selectedValuesTable.textContent = ''
    selectElement.textContent = ''
  }

  #initRuleNameOperatorSelect() {
    const ruleNameOperatorSelect = this.getRuleNameOperatorSelectElement()

    if (ruleNameOperatorSelect.getAttribute('data-controller') === 'select2') {
      this.#addEventListenerForRuleNameOperatorSelect2(ruleNameOperatorSelect)
    } else {
      this.#addEventListenerForRuleNameOperatorSelect(ruleNameOperatorSelect)
    }
  }

  #addEventListenerForRuleNameOperatorSelect(ruleNameOperatorSelect) {
    ruleNameOperatorSelect.addEventListener('change', (event) => {
      // Check if the "operator" has changed the type, like from 'exact_name' to 'name_contains' or vice-versa
      this.#toggleHideContainsInput()
      this.#clearEverything()

      // Focus appropriate input
      if (ruleNameOperatorSelect.value == 'name_contains') {
        this.containsInputTarget.focus()
      } else {
        this.toggleBtnTarget.click()
      }
    })
  }

  #addEventListenerForRuleNameOperatorSelect2(ruleNameOperatorSelect) {
    $(ruleNameOperatorSelect).on('select2:select', (event) => {
      // Check if the "operator" has changed the type, like from 'exact_name' to 'name_contains' or vice-versa
      this.#toggleHideContainsInput()
      this.#clearEverything()

      // Focus appropriate input
      if (ruleNameOperatorSelect.value == 'name_contains') {
        this.containsInputTarget.focus()
      } else {
        this.toggleBtnTarget.click()
      }
    });
  }

  #buildToggleBtnPlaceholder(filterType) {
    let pluralFilterType;
    if (filterType.replace("_filter", '').endsWith('y')) {
      pluralFilterType = filterType.replace("y_filter", "ies")
    } else {
      pluralFilterType = filterType.replace("_filter", "s")
    }

    return 'All ' + pluralFilterType.replace("_", " ").toLowerCase()
  }

  #delay(fn, ms) {
    let timer = 0
    return function(...args) {
      clearTimeout(timer)
      timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
  }

  #createSelectedValuesTableRow(tableBodyElement, value) {
    const rowElement = tableBodyElement.insertRow()
    rowElement.classList.add('checked')

    const firstCell = rowElement.insertCell()
    firstCell.innerHTML = this.#checkboxTableCellContent()
    firstCell.style.width = '1%'

    const secondCell = rowElement.insertCell()
    secondCell.innerText = value

    rowElement.setAttribute('data-node-value', value)
    rowElement.setAttribute('data-node-id', value)
    this.#createToggleCheckmarkEventListener(rowElement)
  }

  #createToggleCheckmarkEventListener(rowElement) {
    rowElement.addEventListener('click', (event) => {
      if (rowElement.classList.contains('checked')) {
        rowElement.classList.remove('checked')
      } else {
        rowElement.classList.add('checked')
      }
    })
  }

  #createOptionForSelect(selectElement, value) {
    const optionElement = document.createElement('option');
    optionElement.value = value;
    optionElement.innerHTML = value;
    optionElement.setAttribute('selected', '')
    optionElement.selected = true
    selectElement.appendChild(optionElement);
  }

  #removeRedudantSelectedRows(selectElement, allNodesTable) {
    const selectedIds = Array.from(selectElement.querySelectorAll('option')).map((option) => option.value)

    if (selectedIds.length > 0) {
      const querySelectorString = selectedIds.map((selectedId) => `tr[data-node-id='${CSS.escape(selectedId)}']`).join(', ')
      allNodesTable.querySelectorAll(querySelectorString).forEach((selectedRow) => selectedRow.remove())
    }
  }

  #checkboxTableCellContent() {
    return `<svg class="checked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#112C7B"/>
              <path d="M12.2069 4.79279C12.3944 4.98031 12.4997 5.23462 12.4997 5.49979C12.4997 5.76495 12.3944 6.01926 12.2069 6.20679L7.20692 11.2068C7.01939 11.3943 6.76508 11.4996 6.49992 11.4996C6.23475 11.4996 5.98045 11.3943 5.79292 11.2068L3.79292 9.20679C3.61076 9.01818 3.50997 8.76558 3.51224 8.50339C3.51452 8.24119 3.61969 7.99038 3.8051 7.80497C3.99051 7.61956 4.24132 7.51439 4.50352 7.51211C4.76571 7.50983 5.01832 7.61063 5.20692 7.79279L6.49992 9.08579L10.7929 4.79279C10.9804 4.60532 11.2348 4.5 11.4999 4.5C11.7651 4.5 12.0194 4.60532 12.2069 4.79279Z" fill="white"/>
              <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#112C7B"/>
            </svg>
            <svg class="unchecked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="white"/>
              <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#D1D5DB"/>
            </svg>`;
  }

  #buildCheckboxSelectDatatableOptions(filterType) {
    const toggleBtn = this.toggleBtnTarget
    const allNodesTable = this.allNodesTableTarget
    const selectedNodesTable = this.selectedNodesTableTarget
    const nodesSearchInput = $(this.searchTarget)
    const selectElement = this.selectTarget

    return {
      "columnDefs": [
        {
          "targets": 0,
          "searchable": false,
          "render": (data, type, row, meta) => {
            return this.#checkboxTableCellContent();
          },
          "data": null
        },{
          "targets": 1,
          "searchable": true,
          "render": (data, type, row, meta) => {
            return row;
          },
        }],
      "order": [[ 1, "desc" ]],
      "searching": true,
      "deferLoading": 0,
      "processing": true,
      "serverSide": true,
      "ordering": false,
      "bLengthChange": false,
      "dom": "tS",
      "ajax": {
        "url": `/complex_filters/search_filters?filter_type=${filterType}`,
        "type": "GET",
        "data": function (data) {
          data.for_datatable = true
          data.search = nodesSearchInput.val()
        }
      },
      "scrollY":        200,
      "scrollCollapse": true,
      "scroller": {
        loadingIndicator: true
      },
      createdRow: (row, data, dataIndex) => {
        row.querySelector('td:first-child').style.width = '1%'
        row.setAttribute('data-node-value', data)
        row.setAttribute('data-node-id', data)
        this.#createToggleCheckmarkEventListener(row)
      },
      "preDrawCallback": (settings) => {
        this.#redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)
      },
      "drawCallback": (settings) => {
        this.#removeRedudantSelectedRows(selectElement, allNodesTable)
      }
    }
  }

  #buildSelectedValuesInnerText(selectedValues) {
    let selectedValuesInnerText = this.toggleBtnTarget.dataset.defaultInnerText

    if (selectedValues.length === 1) {
      selectedValuesInnerText = selectedValues[0]
    } else if (selectedValues.length > 1) {
      const suffix = ((selectedValues.length - 1) === 1) ? 'other' : 'others'
      selectedValuesInnerText = `${selectedValues[0]}, +${selectedValues.length - 1} ${suffix}`
    }

    return selectedValuesInnerText;
  }
}



