import { Controller } from "@hotwired/stimulus"
import { refreshFormulaBuilder } from "./refresh_formula_builder_helper";

// Connects to data-controller="custom-metrics--filter-rule"
export default class extends Controller {
  static values = {
    customerOnlyFilters: Array,
    metricIdentifier: String
  }

  static targets = [
      'orderKindSelect',
      'kindSelect',
      'operatorSelect',
      'ruleNameOperatorSelect'
  ]

  static outlets = ['complex-filters-checkbox-select']

  connect() {
    this.#initSelects()
    this.#setFormulaPartFilterRuleCount()
  }

  focusValueSelect() {
    const ruleNameOperatorSelect = this.ruleNameOperatorSelectTarget
    const ruleNameOperatorType = ruleNameOperatorSelect.value

    if (['contains', 'does_not_contain'].includes(ruleNameOperatorType)) {
      this.element.querySelector("[data-complex-filters-checkbox-select-target='containsInput']").focus()
    } else {
      this.element.querySelector("[data-complex-filters-checkbox-select-target='toggleBtn']").focus()
    }
  }

  disconnect() {
    this.#setFormulaPartFilterRuleCount()
  }

  #initSelects() {
    const customerOnlyFilters = this.customerOnlyFiltersValue
    const kindSelect = this.kindSelectTarget
    const orderKindSelect = this.orderKindSelectTarget
    const complexFiltersCheckboxSelectOutlet = this.complexFiltersCheckboxSelectOutlet

    $(kindSelect).on('select2:select', (event) => {
      if (customerOnlyFilters.includes(kindSelect.value)) {
        $(orderKindSelect).prop('disabled',true);
        orderKindSelect.parentNode.classList.add('hidden');
      } else {
        $(orderKindSelect).prop('disabled',false);
        orderKindSelect.parentNode.classList.remove('hidden');
      }

      complexFiltersCheckboxSelectOutlet.reset(kindSelect.value)
      refreshFormulaBuilder()
    });

    $(orderKindSelect).on('select2:select', (event) => {
      refreshFormulaBuilder()
    })

    $(this.operatorSelectTarget).on('select2:select', (event) => {
      refreshFormulaBuilder()
    })

    $(this.ruleNameOperatorSelectTarget).on('select2:select', (event) => {
      refreshFormulaBuilder()
    })
  }

  #setFormulaPartFilterRuleCount() {
    const selector =  `[data-custom-metrics--metric-formula-input-metric-identifier-value='${this.metricIdentifierValue}'] [data-custom-metrics--metric-formula-input-target="ruleCountContainer"]`
    const ruleCountElement = document.querySelector(selector)

    if (ruleCountElement === null) {
      // Do nothing because the merchant has closed the sidedrawer and
      // there's no ruleCountElement to update
      return;
    }

    const ruleCount = document.querySelectorAll(`[data-custom-metrics--filter-rule-metric-identifier-value="${this.metricIdentifierValue}"]`).length
    if (ruleCount > 0) {
      ruleCountElement.innerHTML = '<div class="flex items-center justify-center h-4 w-4 bg-blue-100 rounded-full">' +
                                     `<span class="text-xs font-medium">${ruleCount}</span>` +
                                   '</div>'
    } else {
      ruleCountElement.innerHTML = ''
    }
  }
}
