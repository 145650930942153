import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["switch", "notification"]

  connect() {
    if (this.hasSwitchTarget) {
      this.switchTarget.addEventListener("click", () => {
        const appSwitcherOverlay = document.getElementById("amp-app-switcher-overlay");
        if (appSwitcherOverlay) {
          appSwitcherOverlay.classList.add("open")
        }

        if (this.hasNotificationTarget) {
          this.notificationTarget.style.visibility = "hidden"
        } else {
          const element = document.getElementsByClassName("amp-app-switcher-notification-badge")?.[0];
          if (element) {
            element.style.visibility = "hidden";
          }
        }
        Rails.ajax({
          url: `/platform/app_switcher_opened`,
          type: 'post',
        });
      })
    }
  }

  close() {
    const appSwitcherOverlay = document.getElementById("amp-app-switcher-overlay");
    if (appSwitcherOverlay) {
      appSwitcherOverlay.classList.remove("open")
    }
  }
}
